import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/spinner";

const REQUIRED_QUERY_PARAMS = ["state", "code", "scope"];

class OAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: "start",
      message: undefined,
    };
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Verify required fields are present
    for (let field of REQUIRED_QUERY_PARAMS) {
      console.log(field, urlParams.get(field));
      if (urlParams.get(field) === null) {
        this.setState({
          progress: "error",
          message: `Missing required field '${field}'`,
        });
        return;
      }
    }

    // Send the oAuth request
    fetch(
      `https://us-central1-age-of-empires-307521.cloudfunctions.net/twitch-rating-charts-oauth?code=${urlParams.get(
        "code"
      )}&state=${urlParams.get("state")}&scope=${urlParams.get("scope")}`
    )
      .then(response => {
        if (response.status == 204) {
          return Promise.resolve();
        } else {
          return response.json();
        }
      })
      .then(data => {
        if (data) {
          this.setState({
            progress: "error",
            message: data.message,
          });
        } else {
          this.setState({
            progress: "complete",
          });
        }
      });
  }

  render() {
    let content = undefined;
    if (this.state.progress === "start") {
      content = (
        <div>
          <h1>Processing oAuth Request...</h1>
          <div
            style={{
              paddingTop: "25px",
              margin: "auto",
              width: "42px",
            }}
          >
            <Spinner></Spinner>
          </div>
        </div>
      );
    } else if (this.state.progress === "error") {
      content = (
        <div>
          <h1>There was an error while processing your request</h1>
          <p>{this.state.message}</p>
        </div>
      );
    } else if (this.state.progress === "complete") {
      content = (
        <div>
          <h1>Success!</h1>
          <p>Processing complete, you may now close this window</p>
        </div>
      );
    }

    return (
      <Layout location={{ pathname: "/404" }}>
        <SEO title="oAuth Processing" />
        <div style={{ textAlign: "center" }}>{content}</div>
      </Layout>
    );
  }
}

export default OAuth;
